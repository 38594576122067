<template>
  <div>
    <va-modal
      :value="isActive"
      ref="modal"
      hideDefaultActions
      class="flex xs12"
      title="Generate Multiple Users"
      size="large"
      ok-text="Ok"
    >
      <div class="mx-0">
        <div style="max-height: 400px; overflow-y: auto;">
          <div>
            <section>
              <strong>Almost done, just one more step!</strong>
              <p>
                In order to generate multiple users, please provide the
                following information:
              </p>
              <p class="pt-3 font-bold">What should users be linked to?</p>
              <div>
                <va-select
                  placeholder="Select a Type"
                  v-model="modalselect.value"
                  @input="change"
                  style="max-width: 100%;"
                  :options="modalselect.options"
                />
              </div>
              <p class="pt-3 font-bold">Inform the {{ modalselect.value.id == 1 ? 'Order Secret' : 'Organization Secret' }}</p>
              <div>
                <va-input
                  :placeholder="modalselect.value.id == 1 ? 'i.e OSIM21148586PL' : 'i.e OGUGELWFWH'"
                  :maxlength="modalselect.value.id == 1 ? 14 : 10"
                  v-model="secret"
                  style="max-width: 100%;"
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <div :slot="'actions'">
        <template>
          <va-button @click="createUser" :color="fieldsFilled ? 'info' : 'success' " :disabled="fieldsFilled">
            {{ fieldsFilled ? "Please, fill all fields" : "Save & Generate Users"}}
          </va-button>
          <va-button color="info" @click="closeModal">
            Close
          </va-button>
        </template>
      </div>
    </va-modal>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOrgType: null,
      secret: '',
      modalselect: {
        value: '',
        options: [
            { id: 1, text: 'Order Secret (Use Order Form)' }, 
            { id: 2, text: 'Organization Secret (Join Organization)' }, 
        ]
      },
    };
  },
  computed: {
    fieldsFilled() {
      return this.modalselect.value === '' || this.secret.length < 3;
    }
  },
  methods: {
    change() {
     this.secret = this.modalselect.value.id == 1 ? this.secret.substring(0, 14) : this.secret.substring(0, 10);
    },
    async closeModal() {
      this.$emit("close");
    },
    async createUser() {
      try {
        this.$emit("submit", {
            secret: this.secret,
            type: this.modalselect.value.id
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style scoped> 
.font-bold {
  font-weight: bold !important;
}
</style>